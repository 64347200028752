// import('../../../../modules/system/assets/js/framework-bundle')

import {
    myFunction,
    MyJsModule,
    otherFunction,
    sub,
} from "./modules/myJsModule";

import {CookieManager} from "../../../../plugins/seba/ockekse/assets/modules/cookieManager";
import Swiper from "swiper";

document.addEventListener("DOMContentLoaded", function () {

    animations();

    const cookieManager = new CookieManager();
    cookieManager.init();

    if (document.querySelector(".swiper")) {
        document.documentElement.style.setProperty(
            "--slider-image-height",
            document.getElementById("swiper-slider-image").offsetHeight + "px");
        document.documentElement.style.setProperty(
            "--slider-image-width",
            document.getElementById("swiper-slider-image").offsetWidth + "px");
    }

    handleMobileNavigation();
    let jobTabs = document.querySelectorAll(".jobs-tab");

    if (jobTabs) {
        jobTabs.forEach(function (e) {
            e.addEventListener("click", function (e) {
                let descriptions =
                        e.currentTarget.parentElement.querySelectorAll(
                            ".jobs-tab-inner",
                        );
                let icons        =
                        e.target.parentElement.querySelectorAll(".show-job-button");

                descriptions.forEach(function (e) {
                    e.classList.toggle("jobs-tab-inner--is-active");
                });

                icons.forEach(function (e) {
                    setTimeout(function () {
                        e.classList.toggle("opacity-0");
                    }, 500);
                });
            });
        });
    }

    let navItems = document.querySelectorAll(".has-items");

    navItems.forEach(function (e) {
        e.addEventListener("click", function (e) {
            console.log("test");
            e.target.classList.toggle("is-active");
            e.target.nextElementSibling.classList.toggle("subitems-active");
        });
    });

    window.addEventListener("resize", (event) => {
        if (document.querySelector(".swiper")) {
            document.documentElement.style.setProperty(
                "--slider-image-height",
                document.getElementById("swiper-slider-image").offsetHeight + "px");
            document.documentElement.style.setProperty(
                "--slider-image-width",
                document.getElementById("swiper-slider-image").offsetWidth + "px");
        }
    });

    //Slider
    const swiper = new Swiper(".mySwiper", {

        grabCursor: true,
    });


    const swiper2 = new Swiper(".slider2-slider", {
        effect           : "fade",
		 autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      	},
        keyboardControl  : false,
        mousewheelControl: false,
        pagination       : {
            el: ".text-swiper-pagination",
        },
        navigation       : {
            nextEl: ".text-swiper-button-next",
            prevEl: ".text-swiper-button-prev",
        },

    });


    swiper.on("transitionEnd", function (e) {
        swiper2.slideTo(swiper.activeIndex)
    });

    swiper2.on("slideChange", function (e) {

        swiper.slideTo(swiper2.activeIndex)
    });

    //job tabs
    const tabs = document.querySelectorAll(".accordion-tab");
    if (tabs) {
        tabs.forEach(function (el) {
            el.onclick = function () {
                console.log(el);

                if (el.classList.contains("open")) {
                    el.classList.remove("open");
                    el.classList.add("closed");
                } else
                    if (el.classList.contains("closed")) {
                        el.classList.remove("closed");
                        el.classList.add("open");
                    }
            };
        });
    }

    //icon slider
    const iconSwiper = new Swiper(".icon-swiper", {
        grabCursor    : true,
        slidesPerView : "auto",
        spaceBetween  : 40,
        centeredSlides: false,


        pagination: {
            el: ".icon-swiper-pagination",
        },
        navigation: {
            nextEl: ".icon-swiper-button-next",
            prevEl: ".icon-swiper-button-prev",
        },
    });

    let btns      = document.querySelectorAll(".swiper-slide--button")
    let popUps    = document.querySelectorAll(".icon-slider--pop-up")
    let closeBtns = document.querySelectorAll(".popup-close-btn")

    closeBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            popUps.forEach((popUp) => {
                popUp.style.display = "none"


            })
        })
    })

    btns.forEach((btn) => {

        btn.addEventListener("click", () => {
            let btnIndex;
            btnIndex = btn.dataset.index

            popUps.forEach((popUp) => {
                if (popUp.dataset.index == btnIndex) {
                    popUp.style.display = "flex"
                }
            })
        })
    })

    //card-slider
    const cardSwiper = new Swiper(".card-swiper", {
        grabCursor    : true,
        slidesPerView : "auto",
        spaceBetween  : 40,
        centeredSlides: false,

        pagination: {
            el: ".card-swiper-pagination",
        },
        navigation: {
            nextEl: ".card-swiper-button-next",
            prevEl: ".card-swiper-button-prev",
        },
    });
});

function handleMobileNavigation() {
    //Get Mobile Navigation components
    let navBtn    = document.getElementById("nav-btn");
    let wrapper   = document.getElementById("navigation-wrapper");
    let mobileNav = document.getElementById("mobile-navigation");

    if (window.innerWidth < 1280) {
        navBtn.addEventListener("click", function () {
            wrapper.classList.toggle("active");
            mobileNav.classList.toggle("wrapper-active");
        });
    }

    if (window.innerWidth >= 1280) {
        let isHidden = wrapper.classList.contains("hidden");
        if (!isHidden) {
            wrapper.classList.add("hidden");
        }
    }
}

function animations() {
    const animationObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log(entry);
                entry.target.classList.add("animation-show");
            }
        });
    });

    const flyInElements = document.querySelectorAll(
        ".fly-in-left, .fly-in-right",
    );
    flyInElements.forEach((el) => animationObserver.observe(el));
}
